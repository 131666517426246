(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/use-query-param/use-query-param.js') >= 0) return;  svs.modules.push('/components/lb-utils/use-query-param/use-query-param.js');
"use strict";


const {
  useEffect,
  useState
} = React;
const {
  useLocation
} = ReactRouterDOM;
const useQueryParam = name => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [queryParam, setQueryParam] = useState(searchParams.get(name));
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const value = searchParams.get(name);
    setQueryParam(current => {
      if (current !== value) {
        return value;
      }
      return current;
    });
  }, [location.search, name]);
  return queryParam;
};
setGlobal('svs.components.lbUtils.useQueryParam', useQueryParam);

 })(window);